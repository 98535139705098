import { Link } from 'gatsby';
import React from 'react';
import route from '../../constants/route';
import Box, { ColumnBox, RowBox } from '../Box';
import Image from '../Image';
import Text from '../Text';

const MobileFooter: React.FC = () => (
  <Box width={1}>
    <ColumnBox
      height={400}
      bg="navy"
      alignItems="center"
      justifyContent="center"
    >
      <Link to={route.root.path}>
        <Box height={200} width={120}>
          <Image name="footerLogoVertical" />
        </Box>
      </Link>
      <Box pl={4} pr={4}>
        <RowBox
          color="white"
          flexWrap="wrap"
          fontSize={2}
          p={1}
          justifyContent="center"
        >
          <Link to={route.about.path}>
            <Text pr={2}>「育児ケアオンライン」とは？</Text>
          </Link>
          {/* <Link to={route.service.path}>
            <Text pl={2} pr={2}>
              コース詳細
            </Text>
          </Link>
          <Link to={route.case.path}>
            <Text pl={2} pr={2}>
              お客様の声
            </Text>
          </Link>
          <Link to={route.flow.path}>
            <Text pl={2} pr={2}>
              ご利用の流れ
            </Text>
          </Link> */}
          {/* <Link to={route.recruit.path}>
              <Text pl={2} pr={2}>
                医療職の方へ
              </Text>
            </Link> */}
        </RowBox>
        <RowBox color="white" flexWrap="wrap" fontSize={1}>
          <a href="https://com-med.co.jp/" target="blank">
            <Text p={2}>運営会社</Text>
          </a>
          <a href="https://com-med.co.jp/privacy.html" target="blank">
            <Text p={2}>プライバシーポリシー</Text>
          </a>
          <a
            href="https://com-med.co.jp/specified_commercial_transactions.html"
            target="blank"
          >
            <Text p={2}>特定商取引法表示</Text>
          </a>
        </RowBox>
      </Box>
    </ColumnBox>
  </Box>
);

export default MobileFooter;
