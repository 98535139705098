import styled from '@emotion/styled';
import {
  alignContent,
  AlignContentProps,
  alignItems,
  AlignItemsProps,
  borders,
  BordersProps,
  bottom,
  BottomProps,
  boxShadow,
  BoxShadowProps,
  color,
  display,
  DisplayProps,
  flexDirection,
  FlexDirectionProps,
  flexWrap,
  FlexWrapProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  position,
  PositionProps,
  right,
  RightProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type BoxProps = AlignContentProps &
  AlignItemsProps &
  BordersProps &
  BottomProps &
  BoxShadowProps &
  ColorProps &
  DisplayProps &
  FlexDirectionProps &
  FlexWrapProps &
  FontSizeProps &
  HeightProps &
  JustifyContentProps &
  LeftProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  PositionProps &
  RightProps &
  SpaceProps &
  TextAlignProps &
  TopProps &
  WidthProps;

const Box = styled.div<BoxProps>`
  ${alignItems};
  ${alignContent};
  ${borders};
  ${bottom};
  ${boxShadow};
  ${color};
  ${display};
  ${flexDirection};
  ${flexWrap};
  ${fontSize};
  ${justifyContent};
  ${height};
  ${left};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${position};
  ${right};
  ${space};
  ${textAlign};
  ${top};
  ${width};
`;

export default Box;
