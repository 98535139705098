import styled from '@emotion/styled';
import { color } from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type CircleProps = ColorProps & { width: number };

const Circle = styled.span<CircleProps>`
  ${color};
  height: ${props => props.width}px;
  width: ${props => props.width}px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
`;

export default Circle;
