import React from 'react';
import { connect } from 'react-redux';
import { getIsLoggedIn } from '../../redux/auth/selectors';
import { RootState } from '../../redux/rootReducer';
import Box, { RowBox } from '../Box';
import Image from '../Image';
import Text from '../Text';
import Button from './Button';

interface StateProps {
  isLoggedIn: boolean;
}
interface OwnProps {
  handleClick?: () => void;
}
type Props = OwnProps & StateProps;

const LineLoginButton: React.FC<Props> = ({
  handleClick = () => null,
  isLoggedIn,
}) => {
  const onClick = React.useCallback(() => {
    handleClick();
  }, [handleClick]);
  return (
    <Button
      bg="line"
      borderRadius={55 / 2}
      color="white"
      width={[200, 250, 250]}
      height={55}
      onClick={onClick}
    >
      <RowBox alignItems="center" pl={10} pr={10}>
        <Box height={55} width={55}>
          <Image name="lineIcon" />
        </Box>
        <Text margin="auto" fontWeight="bold" fontSize={[4, 5, 5]}>
          {isLoggedIn ? 'LINEでログイン' : 'LINEで会員登録'}
        </Text>
      </RowBox>
    </Button>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps)(LineLoginButton);
