/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';

interface OwnProps {
  description?: string;
  lang?: string;
  meta?: [];
  keywords?: string[];
  title: string;
  pathname?: string;
}

const SEO: React.SFC<OwnProps> = ({
  description = '',
  lang = 'ja',
  meta = [],
  keywords = ['育児', 'オンライン', '相談'],
  title,
  pathname = '/',
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const url = `${site.siteMetadata.siteUrl}${pathname || '/'}`;
  const ogpImage = site.siteMetadata.image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          content: metaDescription,
          name: `description`,
        },
        {
          content: title,
          property: `og:title`,
        },
        {
          content: metaDescription,
          property: `og:description`,
        },
        {
          content: `website`,
          property: `og:type`,
        },
        {
          content: url,
          property: `og:url`,
        },
        {
          content: ogpImage,
          property: `og:image`,
        },
        {
          content: '育児ケアオンライン',
          property: `og:site_name`,
        },
        {
          content: `ja_JP`,
          property: `og:locale`,
        },
        {
          content: `282963539090758`,
          name: `fb:app_id`,
        },
        {
          content: `summary`,
          name: `twitter:card`,
        },
        {
          content: site.siteMetadata.author,
          name: `twitter:creator`,
        },
        {
          content: title,
          name: `twitter:title`,
        },
        {
          content: metaDescription,
          name: `twitter:description`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                content: keywords.join(`, `),
                name: `keywords`,
              }
            : [],
        )
        .concat(meta)}
    />
  );
};

export default SEO;
