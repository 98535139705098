const theme = {
  colors: {
    black: '#000e1a',
    blue: '#007ce0',
    'brownish-grey': '#707070',
    'dark-grey': '#2e2e2e',
    'dusty-orange': '#f58223',
    'light-grey': '#D8D8D8',
    'light-orange': '#ffbd80',
    line: '#00C300',
    navy: '#29314B',
    'navy-dark': '#181F2F',
    'off-white': '#fff5e6',
    'very-light-pink': '#FFFCF6',
    white: '#ffffff',
  },
  // fontSizes
  // 0: 11px
  // 1: 12px
  // 2: 13px
  // 3: 14px
  // 4: 16px
  // 5: 20px
  // 6: 22px
  // 7: 24px
  // 8: 28px
  // 9: 32px
  // 10: 40px
  fontSizes: [11, 12, 13, 14, 16, 20, 22, 24, 28, 32, 40],
  shadows: ['0 0 4px rgba(0, 0, 0, .125)', '0 0 24px rgba(0, 0, 0, .125)'],
  // space
  // 0: 0px
  // 1: 4px
  // 2: 8px
  // 3: 16px
  // 4: 24px
  // 5: 32px
  // 6: 64px
  // 7: 128px
  // 8: 256px
  space: [0, 4, 8, 16, 24, 32, 64, 128, 256],
};

export type Theme = typeof theme;

export default theme;
