import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { State as SpinnerState } from './reducer';

export const spinnerSelector = (state: RootState): SpinnerState =>
  state.spinner;

export const getOpen = createSelector<
  RootState,
  SpinnerState,
  SpinnerState['open']
>(
  spinnerSelector,
  spinner => spinner.open,
);
