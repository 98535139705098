import styled from '@emotion/styled';
import {
  color,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type TextWithBorderProps = ColorProps &
  FontSizeProps &
  FontWeightProps &
  LetterSpacingProps &
  SpaceProps &
  TextAlignProps;

const TextWithBorder = styled.div<TextWithBorderProps>`
  ${color};
  ${fontSize};
  ${fontWeight};
  ${letterSpacing};
  ${space};
  ${textAlign};
  background-image: linear-gradient(
    to right,
    #000,
    #000 1px,
    transparent 1px,
    transparent 4px
  );
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 5px 1px;
`;

export default TextWithBorder;
