import { createSelector } from 'reselect';
import { RootState } from '../rootReducer';
import { State as SignUpDialogState } from './reducer';

export const signUpDialogSelector = (state: RootState): SignUpDialogState =>
  state.signUpDialog;

export const getOpen = createSelector<
  RootState,
  SignUpDialogState,
  SignUpDialogState['open']
>(
  signUpDialogSelector,
  signUpDialog => signUpDialog.open,
);
