import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  borders,
  BordersProps,
  color,
  display,
  DisplayProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  height,
  HeightProps,
  space,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

export const disabledStyle = (props: ButtonProps) => {
  return props.disabled
    ? css`
        opacity: 0.4;
        pointer-events: none;
      `
    : css``;
};

type ButtonProps = BordersProps &
  ColorProps &
  DisplayProps &
  FontSizeProps &
  FontWeightProps &
  HeightProps &
  SpaceProps &
  WidthProps & {
    disabled?: boolean;
  };

const Button = styled.div<ButtonProps>`
  -ms-user-select: none; /* IE 10+ */
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  ${borders};
  ${color};
  ${display};
  ${fontSize};
  ${fontWeight};
  ${height};
  ${space};
  ${width};
  ${disabledStyle};
`;

export default Button;
