import React from 'react';
import Button from './Button';
import Text from '../Text';

interface OwnProps {
  bg?: 'dusty-orange' | 'line';
  color?: 'white';
  disabled?: boolean;
  fontSize?: number | number[];
  fontWeight?: 'bold';
  height: number | number[];
  label: string;
  width: number | number[];
  onClick?: () => void;
}

const FlatButton: React.FC<OwnProps> = ({
  bg = 'dusty-orange',
  color = 'white',
  disabled = false,
  fontSize = 4,
  fontWeight = 'bold',
  height,
  label,
  width,
  onClick = () => undefined,
}) => {
  let borderRadius: number | number[];
  if (typeof height === 'number') {
    borderRadius = height / 2;
  } else {
    borderRadius = height.map(h => h / 2);
  }
  return (
    <Button
      bg={bg}
      borderRadius={borderRadius}
      color={color}
      disabled={disabled}
      display="flex"
      fontSize={fontSize}
      fontWeight={fontWeight}
      height={height}
      width={width}
      onClick={onClick}
    >
      <Text margin="auto">{label}</Text>
    </Button>
  );
};

export default FlatButton;
