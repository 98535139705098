const RESPONSE_TYPE = 'code';
const STATE = Date.now();
const SCOPE = 'profile%20openid';

const { LINE_CHANNEL_ID } = process.env;
const { LINE_REDIRECT_URL } = process.env;

const LINE_LOGIN_URL = `https://access.line.me/oauth2/v2.1/authorize?response_type=${RESPONSE_TYPE}&client_id=${LINE_CHANNEL_ID}&redirect_uri=${LINE_REDIRECT_URL}&state=${STATE}&scope=${SCOPE}&bot_prompt=aggressive`;

export const lineLoginUrl = LINE_LOGIN_URL;
