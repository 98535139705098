import styled from '@emotion/styled';
import {
  color,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  letterSpacing,
  LetterSpacingProps,
  lineHeight,
  LineHeightProps,
  space,
  SpaceProps,
  style,
  textAlign,
  TextAlignProps,
  verticalAlign,
  VerticalAlignProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

const whiteSpace = style({
  cssProperty: 'whiteSpace',
  prop: 'whiteSpace',
});

const textDecoration = style({
  cssProperty: 'textDecoration',
  prop: 'textDecoration',
});

type TextProps = ColorProps &
  FontSizeProps &
  FontWeightProps &
  LetterSpacingProps &
  LineHeightProps &
  SpaceProps &
  TextAlignProps &
  VerticalAlignProps & {
    whiteSpace?: string;
    textDecoration?: string;
  };

const Text = styled.div<TextProps>`
  ${color};
  ${fontSize};
  ${fontWeight};
  ${letterSpacing};
  ${lineHeight};
  ${space};
  ${textAlign};
  ${textDecoration}
  ${verticalAlign}
  ${whiteSpace};
`;

export default Text;
