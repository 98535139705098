import CloseIcon from '@material-ui/icons/CloseRounded';
import { Link } from 'gatsby';
import React from 'react';
import route from '../../constants/route';
import Box, { ColumnBox, RowBox } from '../Box';
import Image from '../Image';
import Text from '../Text';

interface OwnProps {
  handleClose: () => void;
}
type Props = OwnProps;

const DrawerContent: React.FC<Props> = ({ handleClose }) => {
  return (
    <ColumnBox width="85vw" p={3} alignItems="center">
      <RowBox width={1} justifyContent="flex-end" p={2}>
        <CloseIcon onClick={handleClose} color="primary" />
      </RowBox>
      <ColumnBox width={1} color="dusty-orange" fontSize={2} p={2}>
        <Link to={route.about.path}>
          <Text p={2} fontWeight="bold">
            「育児ケアオンライン」とは？
          </Text>
        </Link>
        {/* <Link to={route.service.path}>
          <Text p={2} fontWeight="bold">
            コース詳細
          </Text>
        </Link>
        <Link to={route.case.path}>
          <Text p={2} fontWeight="bold">
            お客様の声
          </Text>
        </Link>
        <Link to={route.flow.path}>
          <Text p={2} fontWeight="bold">
            ご利用の流れ
          </Text>
        </Link> */}
        {/* <Link to={route.recruit.path}>
              <Text pl={2} pr={2}>
                医療職の方へ
              </Text>
            </Link> */}
      </ColumnBox>
      {/* <Box p={3}>
        <LineLoginButton handleClick={handleClick} />
      </Box> */}
      <ColumnBox width={1} color="brownish-grey" fontSize={1} p={3}>
        <a href="https://com-med.co.jp/" target="blank">
          <Text p={2}>運営会社</Text>
        </a>
        <a href="https://com-med.co.jp/privacy.html" target="blank">
          <Text p={2}>プライバシーポリシー</Text>
        </a>
        <a
          href="https://com-med.co.jp/specified_commercial_transactions.html"
          target="blank"
        >
          <Text p={2}>特定商取引法表示</Text>
        </a>
      </ColumnBox>
      <Box width={1 / 3} pt={5} pb={3}>
        <Image name="drawerLogo" />
      </Box>
    </ColumnBox>
  );
};

export default DrawerContent;
