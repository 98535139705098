import { Location, LocationContext } from '@reach/router';
import { Link } from 'gatsby';
import React from 'react';
import route from '../../constants/route';
import LayoutContext from '../../contexts/LayoutContext';
import Box, { ColumnBox, RowBox } from '../Box';
import Circle from '../Circle';
import Image from '../Image';
import Text from '../Text';

const HeaderItem = React.memo(
  (props: {
    location: LocationContext['location'];
    pathname: string;
    title: string;
  }) => (
    <Link to={props.pathname}>
      <Box position="relative">
        <Text
          fontSize={4}
          p={3}
          whiteSpace="nowrap"
          fontWeight="bold"
          color={
            (props.location.pathname.includes(props.pathname) &&
              'dusty-orange') ||
            'black'
          }
        >
          {props.title}
        </Text>
        {props.location.pathname.includes(props.pathname) && (
          <ColumnBox
            alignItems="center"
            position="absolute"
            width={1}
            bottom={0}
          >
            <Circle bg="light-orange" width={8} style={{ padding: 0 }} />
          </ColumnBox>
        )}
      </Box>
    </Link>
  ),
);

interface OwnProps {
  isSimple?: boolean;
}
type Props = OwnProps;

const Menu = React.memo(() => (
  <Location>
    {({ location }) => (
      <RowBox justifyContent="flex-end" m={3}>
        <HeaderItem
          location={location}
          pathname={route.about.path}
          title="「育児ケアオンライン」とは？"
        />
        {/* <HeaderItem
          location={location}
          pathname={route.service.path}
          title="コース詳細"
        />
        <HeaderItem
          location={location}
          pathname={route.case.path}
          title="お客様の声"
        />
        <HeaderItem
          location={location}
          pathname={route.flow.path}
          title="ご利用の流れ"
        /> */}
        {/* <Link to={route.recruit.path}>
          <Text fontSize={3} p={3} whiteSpace="nowrap">
            医療職の方へ
          </Text>
        </Link> */}
      </RowBox>
    )}
  </Location>
));

const Header: React.FC<Props> = ({ isSimple = false }) => {
  const { isMobile } = React.useContext(LayoutContext);
  return !isMobile ? (
    <Location>
      {({ location }) => {
        return location.pathname === route.root.path ? (
          <RowBox
            position="absolute"
            top={0}
            height={90}
            alignItems="center"
            justifyContent="space-between"
            style={{ zIndex: isMobile ? 0 : 10 }}
            width="85vw"
            pl={['3%', '7%', '8%']}
          >
            <Box pl={3}>
              <Link to={route.root.path}>
                <Box width={260} height={57}>
                  <Image name="orangeLogo" />
                </Box>
              </Link>
            </Box>
            {!isSimple && !isMobile && <Menu />}
          </RowBox>
        ) : (
          <RowBox
            bg="white"
            boxShadow={`0 0 ${isMobile ? 0 : 2}px rgba(0,0,0,0.2)`}
            height={90}
            alignItems="center"
            justifyContent="space-between"
            style={{ zIndex: isMobile ? 0 : 10 }}
          >
            <Box pl={3}>
              <Link to={route.root.path}>
                <Box width={260} height={57}>
                  <Image name="orangeLogo" />
                </Box>
              </Link>
            </Box>
            {!isSimple && !isMobile && <Menu />}
          </RowBox>
        );
      }}
    </Location>
  ) : (
    <Location>
      {({ location }) => {
        return (
          location.pathname !== route.root.path && (
            <Box p={3} bg="very-light-pink">
              <Link to={route.root.path}>
                <Box width="60vw" minHeight={1}>
                  <Image name="orangeLogo" />
                </Box>
              </Link>
            </Box>
          )
        );
      }}
    </Location>
  );
};

export default Header;
