import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

interface OwnProps {
  name:
    | 'aboutSceneAfter'
    | 'aboutSceneBefore'
    | 'aboutSceneMobile'
    | 'aboutSceneUse'
    | 'caseStudyButton'
    | 'caseStudy1'
    | 'caseStudy2'
    | 'caseStudy1Mobile'
    | 'caseStudy2Mobile'
    | 'course1'
    | 'course2'
    | 'course3'
    | 'dotLine'
    | 'drawerLogo'
    | 'feature1'
    | 'feature2'
    | 'feature3'
    | 'flowMobile'
    | 'flow1'
    | 'flow2'
    | 'flow3'
    | 'flow4'
    | 'footerLogo'
    | 'footerLogoVertical'
    | 'heroHeader'
    | 'heroHeaderMobile'
    | 'lineIcon'
    | 'logoIcon'
    | 'normalLogo'
    | 'orangeLogo'
    | 'priceMobile'
    | 'service'
    | 'serviceMobile'
    | 'serviceCoursesMobile'
    | 'supporterNakamura'
    | 'supporterSatake'
    | 'supporterWatanabe'
    | 'topCaseStudy1'
    | 'topCaseStudy2'
    | 'topCaseStudyMobile'
    | 'topServiceLeft1'
    | 'topServiceLeft2'
    | 'topServiceRight1'
    | 'topServiceRight2'
    | 'topServiceMobile1'
    | 'topServiceMobile2'
    | 'topServiceMobile3'
    | 'topServiceMobile4'
    | 'topAbout';
}

const Image: React.FC<OwnProps> = ({ name }) => (
  <StaticQuery
    query={graphql`
      query {
        aboutSceneAfter: file(relativePath: { eq: "about-scene-after.png" }) {
          childImageSharp {
            fluid(maxWidth: 1052) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        aboutSceneBefore: file(relativePath: { eq: "about-scene-before.png" }) {
          childImageSharp {
            fluid(maxWidth: 1052) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        aboutSceneMobile: file(relativePath: { eq: "about-scene-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 526) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        aboutSceneUse: file(relativePath: { eq: "about-scene-use.png" }) {
          childImageSharp {
            fluid(maxWidth: 1052) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        caseStudyButton: file(relativePath: { eq: "case-study-button.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        caseStudy1: file(relativePath: { eq: "case-study-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 1052) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        caseStudy2: file(relativePath: { eq: "case-study-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 1052) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        caseStudy1Mobile: file(
          relativePath: { eq: "case-study-1-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 526) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        caseStudy2Mobile: file(
          relativePath: { eq: "case-study-2-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 526) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        course1: file(relativePath: { eq: "course-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        course2: file(relativePath: { eq: "course-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        course3: file(relativePath: { eq: "course-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        dotLine: file(relativePath: { eq: "dot-line.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        drawerLogo: file(relativePath: { eq: "drawer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        feature1: file(relativePath: { eq: "feature-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        feature2: file(relativePath: { eq: "feature-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        feature3: file(relativePath: { eq: "feature-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        flowMobile: file(relativePath: { eq: "flow-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        flow1: file(relativePath: { eq: "flow-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        flow2: file(relativePath: { eq: "flow-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        flow3: file(relativePath: { eq: "flow-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        flow4: file(relativePath: { eq: "flow-4.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        orangeLogo: file(relativePath: { eq: "orange-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        heroHeader: file(relativePath: { eq: "hero-header.png" }) {
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        heroHeaderMobile: file(relativePath: { eq: "hero-header-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        lineIcon: file(relativePath: { eq: "line-icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        logoIcon: file(relativePath: { eq: "logo-icon.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        normalLogo: file(relativePath: { eq: "normal-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        priceMobile: file(relativePath: { eq: "price-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        service: file(relativePath: { eq: "service.png" }) {
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        serviceMobile: file(relativePath: { eq: "service-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        supporterNakamura: file(
          relativePath: { eq: "supporter-nakamura.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        serviceCoursesMobile: file(
          relativePath: { eq: "service-courses-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        supporterSatake: file(relativePath: { eq: "supporter-satake.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        supporterWatanabe: file(
          relativePath: { eq: "supporter-watanabe.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topAbout: file(relativePath: { eq: "top-about.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topCaseStudy1: file(relativePath: { eq: "case-study-01.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topCaseStudy2: file(relativePath: { eq: "case-study-02.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topCaseStudyMobile: file(
          relativePath: { eq: "top-case-study-mobile.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceLeft1: file(relativePath: { eq: "top-service-left-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceLeft2: file(relativePath: { eq: "top-service-left-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceRight1: file(
          relativePath: { eq: "top-service-right-1.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceRight2: file(
          relativePath: { eq: "top-service-right-2.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceMobile1: file(
          relativePath: { eq: "top-service-mobile-1.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceMobile2: file(
          relativePath: { eq: "top-service-mobile-2.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceMobile3: file(
          relativePath: { eq: "top-service-mobile-3.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        topServiceMobile4: file(
          relativePath: { eq: "top-service-mobile-4.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        footerLogo: file(relativePath: { eq: "footer-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        footerLogoVertical: file(
          relativePath: { eq: "footer-logo-vertical.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data[name].childImageSharp.fluid}
        style={{
          userSelect: 'none',
        }}
      />
    )}
  />
);
export default Image;
