import styled from '@emotion/styled';
import {
  alignItems,
  AlignItemsProps,
  borders,
  BordersProps,
  bottom,
  BottomProps,
  boxShadow,
  BoxShadowProps,
  color,
  flexWrap,
  FlexWrapProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  justifyContent,
  JustifyContentProps,
  left,
  LeftProps,
  maxWidth,
  MaxWidthProps,
  minHeight,
  MinHeightProps,
  minWidth,
  MinWidthProps,
  overflow,
  OverflowProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps,
  top,
  TopProps,
  width,
  WidthProps,
} from 'styled-system';
import { ColorProps } from '../../types/styled-system';

type ColumnBoxProps = AlignItemsProps &
  BordersProps &
  BottomProps &
  BoxShadowProps &
  ColorProps &
  FlexWrapProps &
  FontSizeProps &
  JustifyContentProps &
  HeightProps &
  LeftProps &
  MaxWidthProps &
  MinHeightProps &
  MinWidthProps &
  OverflowProps &
  PositionProps &
  SpaceProps &
  TextAlignProps &
  TopProps &
  WidthProps;

const ColumnBox = styled.div<ColumnBoxProps>`
  ${alignItems};
  ${borders};
  ${bottom};
  ${boxShadow};
  ${color};
  ${flexWrap};
  ${fontSize};
  ${justifyContent};
  ${height};
  ${left};
  ${maxWidth};
  ${minHeight};
  ${minWidth};
  ${overflow};
  ${position};
  ${textAlign};
  ${space};
  ${top};
  ${width};
  display: flex;
  flex-direction: column;
`;

export default ColumnBox;
