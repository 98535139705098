import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Modal from '@material-ui/core/Modal';
import { createMuiTheme } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import MenuIcon from '@material-ui/icons/MenuRounded';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { COPYRIGHT_HEIGHT } from '../../constants/styles';
import { RootState } from '../../redux/rootReducer';
import { getOpen } from '../../redux/spinner/selectors';
import theme from '../../styles/theme';
import Box, { ColumnBox } from '../Box';
import DrawerContent from '../DrawerContent';
import Footer from '../Footer';
import Header from '../Header';
import SignUpDialog from '../SignUpDialog';
import Text from '../Text';

const apiKey = process.env.STRIPE_API_KEY || '';
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors['dusty-orange'],
    },
  },
});
interface StateProps {
  open: boolean;
}
interface OwnProps {
  isMobile: boolean;
  isSimple: boolean;
  noFooter: boolean;
  noHeader: boolean;
}
type Props = OwnProps & StateProps;

const App: React.FC<Props> = ({
  children,
  isMobile,
  isSimple,
  noFooter,
  noHeader,
  open,
}) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [stripe, setStripe] = React.useState(null);
  React.useEffect(() => {
    setStripe((window as any).Stripe(apiKey));
  }, []);
  const handleDrawerClose = React.useCallback(() => setDrawerOpen(false), []);
  const handleDrawerOpen = React.useCallback(() => setDrawerOpen(true), []);
  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={muiTheme}>
        <ColumnBox minHeight="100vh" position="relative">
          {!noHeader && <Header isSimple={isSimple} />}
          <main>
            <StripeProvider stripe={stripe}>
              <Box width={1}>{children}</Box>
            </StripeProvider>
          </main>
          {!noFooter && <Footer />}
          <ColumnBox
            mt="auto"
            width={1}
            bg="navy-dark"
            height={COPYRIGHT_HEIGHT}
          >
            <Text
              color="white"
              textAlign="center"
              fontSize={2}
              fontWeight={500}
              m="auto"
            >
              ©COMMED, Inc. ALL rights reserved.
            </Text>
          </ColumnBox>
          {isMobile && !isSimple && (
            <Fab
              disableRipple
              onClick={handleDrawerOpen}
              size="small"
              style={{
                backgroundColor: 'white',
                position: 'fixed',
                right: theme.space[3],
                top: theme.space[3],
              }}
            >
              <MenuIcon color="primary" />
            </Fab>
          )}
          {isMobile && !isSimple && (
            <SwipeableDrawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
              onOpen={handleDrawerOpen}
              variant="temporary"
            >
              <DrawerContent handleClose={handleDrawerClose} />
            </SwipeableDrawer>
          )}
        </ColumnBox>
        <Modal open={open} BackdropProps={{ invisible: true }}>
          <ColumnBox
            width={1}
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
          </ColumnBox>
        </Modal>
        <SignUpDialog />
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  open: getOpen(state),
});

export default connect(mapStateToProps)(App);
