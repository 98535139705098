/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import App from './App';
import './Layout.css';

interface OwnProps {
  isMobile: boolean;
  isSimple?: boolean;
  noFooter?: boolean;
  noHeader?: boolean;
}
type Props = OwnProps;

const Layout: React.FC<Props> = ({
  children,
  isMobile,
  isSimple = false,
  noFooter = false,
  noHeader = false,
}) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <App
        isMobile={isMobile}
        isSimple={isSimple}
        noFooter={noFooter}
        noHeader={noHeader}
      >
        {children}
      </App>
    )}
  />
);

export default Layout;
