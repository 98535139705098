export default {
  about: {
    keywords: [`育児`, `オンライン相談`, `育児ケアオンライン`],
    path: '/about',
    title: '「育児ケアオンライン」とは？',
  },
  account: {
    path: '/account',
  },
  accountLogin: {
    path: '/account/login',
  },
  accountOrder: {
    path: '/account/order',
  },
  accountOrderCompletion: {
    path: '/account/order/completion',
  },
  accountOrderSample: {
    path: '/account/order-sample',
  },
  accountOrderSampleCompletion: {
    path: '/account/order-sample/completion',
  },
  accountRegistration: {
    path: '/account/registration',
  },
  accountRegistrationCompletion: {
    path: '/account/registration/completion',
  },
  case: {
    keywords: [`育児`, `オンライン相談`, `お客様の声`],
    path: '/case',
    title: 'お客様の声',
  },
  flow: {
    keywords: [`育児`, `オンライン相談`, `料金`, `質問`],
    path: '/flow',
    title: 'ご利用の流れ、料金、よくあるご質問',
  },
  recruit: {
    keywords: [`育児`, `オンライン相談`, `医療職の方へ`],
    path: '/recruit',
    title: '医療職の方へ',
  },
  root: {
    keywords: [`育児`, `オンライン相談`],
    path: '/',
    title: 'Home',
  },
  service: {
    keywords: [`育児`, `オンライン相談`, `コース詳細`],
    path: '/service',
    title: 'コース詳細',
  },
  terms: {
    keywords: [`育児`, `オンライン相談`, `利用規約`],
    path: '/terms',
    title: '利用規約',
  },
};
