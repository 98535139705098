import Dialog from '@material-ui/core/Dialog';
import { navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import { lineLoginUrl } from '../../constants/lineLogin';
import route from '../../constants/route';
import { getIsLoggedIn } from '../../redux/auth/selectors';
import { RootState } from '../../redux/rootReducer';
import { close as closeSignUpDialogAction } from '../../redux/signUpDialog/actions';
import { getOpen } from '../../redux/signUpDialog/selectors';
import Box, { ColumnBox } from '../Box';
import Button, { LineLoginButton } from '../Button';

import Image from '../Image';
import Text from '../Text';

interface StateProps {
  isLoggedIn: boolean;
  open: boolean;
}
interface DispatchProps {
  closeSignUpDialog: () => void;
}
type Props = StateProps & DispatchProps;

const SignUpDialog: React.FC<Props> = ({
  open,
  closeSignUpDialog,
  isLoggedIn,
}) => {
  const handleClick = React.useCallback(() => {
    if (isLoggedIn) {
      navigate(route.account.path);
      closeSignUpDialog();
    } else {
      window.location.href = lineLoginUrl;
    }
  }, [closeSignUpDialog, isLoggedIn]);
  return (
    <Dialog open={open} onClose={closeSignUpDialog} maxWidth={false}>
      <ColumnBox
        width={1}
        height="auto"
        pt={[5, 5, 6]}
        pb={[5, 5, 6]}
        pl={[4, 4, 6]}
        pr={[4, 4, 6]}
        alignItems="center"
        style={{ overflowX: 'hidden' }}
      >
        <Box height={[60, 80]} width={1} p={2}>
          <Image name="orangeLogo" />
        </Box>
        <ColumnBox width={1} alignItems="center">
          <Text
            fontSize={[0, 2, 3]}
            textAlign="center"
            pt={3}
            pb={3}
            lineHeight={1.71}
          >
            当サービスはLINEと連携することで
            <br />
            使用することができます。
            <br />
            下記より連携してください。
          </Text>
          <Box p={2}>
            <a href={lineLoginUrl}>
              <LineLoginButton />
            </a>
          </Box>
          <ColumnBox p={2} alignItems="center" width={1}>
            <Button onClick={handleClick} fontSize={2} color="blue">
              すでに会員登録済みの方はこちら
            </Button>
          </ColumnBox>
          {/* <Line width={1} bg="light-grey" mt={3} mb={3} />
        <Link to={route.flow.path}>
          <ColumnBox
            bg="white"
            border="solid 2px"
            borderColor="dusty-orange"
            borderRadius={4}
            p={4}
          >
            <ColumnBox m="auto" alignItems="center">
              <Text
                color="dusty-orange"
                fontSize={7}
                fontWeight={900}
                letterSpacing={1}
                pb={3}
              >
                INFORMATION
              </Text>
              <Text fontSize={5} fontWeight="bold">
                ご利用の流れ、料金、よくあるご質問
              </Text>
            </ColumnBox>
          </ColumnBox>
        </Link> */}
        </ColumnBox>
      </ColumnBox>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isLoggedIn: getIsLoggedIn(state),
    open: getOpen(state),
  };
};
const mapDipatchToProps = {
  closeSignUpDialog: closeSignUpDialogAction,
};

export default connect(
  mapStateToProps,
  mapDipatchToProps,
)(SignUpDialog);
